import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ALLIANCES_API_URL, Campaign, Source } from '@cocha/ngx-codex';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlliancesService {
  private alliancesData: Campaign[] = [];
  private source!: Source;
  private campaignSubject$ = new BehaviorSubject<Campaign | undefined>(undefined);

  constructor(
    private http: HttpClient,
    @Inject(ALLIANCES_API_URL) private apiUrl: string,
  ) { }

  get campaign$() {
    return this.campaignSubject$.asObservable();
  }

  getCampaign() {
    return this.campaignSubject$.value;
  }

  setSource(source: Source): void {
    this.source = source;
    this.getAlliancesData().subscribe(alliances => {
      const alliancesConfigs = Array.isArray(alliances) ? alliances : (alliances.general || []);
      alliancesConfigs.forEach(alliance => {
        if (alliance.type === 'descuento') {
          alliance.active = alliance.discount !== null;
        } else {
          const { rates: { previous = 0, current = 0 } = {} } = alliance;
          alliance.active = !!previous && (previous < current);
        }
      });
      this.alliancesData = alliancesConfigs;
      const alliance = this.alliancesData.find(({ source }) => source === this.source);
      this.campaignSubject$.next(alliance);
    });
  }

  private getAlliancesData(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }
}
