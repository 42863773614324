import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i1 from '@angular/common/http';
const ALLIANCES_API_URL = new InjectionToken('');
class AlliancesService {
  constructor(http, apiUrl) {
    this.http = http;
    this.apiUrl = apiUrl;
    this.alliancesData = [];
    this.campaignSubject$ = new BehaviorSubject(undefined);
  }
  get campaign$() {
    return this.campaignSubject$.asObservable();
  }
  getCampaign() {
    return this.campaignSubject$.value;
  }
  setSource(source) {
    this.source = source;
    this.getAlliancesData().subscribe(alliances => {
      alliances.forEach(alliance => {
        if (alliance.type === 'descuento') {
          alliance.active = alliance.discount !== null;
        } else {
          const {
            rates: {
              previous = 0,
              current = 0
            } = {}
          } = alliance;
          alliance.active = !!previous && previous < current;
        }
      });
      this.alliancesData = alliances;
      const alliance = this.alliancesData.find(({
        source
      }) => source === this.source);
      this.campaignSubject$.next(alliance);
    });
  }
  getAlliancesData() {
    return this.http.get(this.apiUrl);
  }
  static {
    this.ɵfac = function AlliancesService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AlliancesService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(ALLIANCES_API_URL));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AlliancesService,
      factory: AlliancesService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AlliancesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [ALLIANCES_API_URL]
    }]
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ALLIANCES_API_URL, AlliancesService };
