<div #containerElem class="tips-bg">
  <h2 class="tips-title" [innerHTML]="title()"></h2>
  @if (isMobile()) {
    <cx-carousel [slideMode]="'slide'" [arrows]="true" [dots]="false" [animationSpeed]="0.5">
      @for (deal of data(); track deal; let i = $index) {
        <div class="tip-box body br-8 bs-md hover:bs-md" cx-carousel-item
          (click)="goTo(deal.deeplink)">
          <div class="body " [ngClass]="{'img flex-center-center': deal.sourceType === 'link'}">
            <div class="title">
              @switch (deal.sourceType) {
                @case ('link') {
                  <div class="image" [ngStyle]="{'background-image': 'url(' + deal.image + ')'}"></div>
                  @if (deal.title) {
                    <h3 class="mb-2 fw-500" [innerHTML]="deal.title"></h3>
                  }
                }
                @case ('icon') {
                  <i class="{{deal.icon}}"></i>
                  @if (deal.title) {
                    <h3 class="mb-2 fw-500" [innerHTML]="deal.title"></h3>
                  }
                }
              }
            </div>
            @if (deal.subtitle) {
              <p class="mb-4 fw-300 fs-base" [innerHTML]="deal.subtitle"></p>
            }
          </div>
        </div>
      }
    </cx-carousel>
  } @else {
    @for (col of dataByColumns(); track col) {
      <div class="tips-wrapper">
        @for (deal of col; track deal; let i = $index) {
          <div class="tip-box br-8 bs-md hover:bs-md "
            (click)="goTo(deal.deeplink)">
            <div class="body" [ngClass]="{'img': deal.sourceType === 'link'}">
              <div class="title">
                @switch (deal.sourceType) {
                  @case ('link') {
                    <div class="image" [ngStyle]="{'background-image': 'url(' + deal.image + ')'}">
                    </div>
                    @if (deal.title) {
                      <h3 class="mb-4 fw-500" [innerHTML]="deal.title"></h3>
                    }
                  }
                  @case ('icon') {
                    <i class="{{deal.icon}}"></i>
                    @if (deal.title) {
                      <h3 class="mb-4 fw-500" [innerHTML]="deal.title"></h3>
                    }
                  }
                }
              </div>
              @if (deal.subtitle) {
                <p class="mb-4 fw-300 fs-base" [innerHTML]="deal.subtitle"></p>
              }
            </div>
          </div>
        }
      </div>
    }
  }
</div>
