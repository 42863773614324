<div class="most-seen">
  @if (title()) {
    <h2 class="title text-center">{{title()}}</h2>
  }
  @if (!isMobile()) {
    <div class="content-box d-flex justify-content-around align-items-start">
      @for (seen of (mostSeen() | groupBy:'type'); track seen) {
        <div class="flex-column">
          <h4 class="title-section text-dark-gray fw-500">{{ seen.key }}</h4>
          <ul class="box-links">
            @for (link of seen.value; track link) {
              <li>
                <a attr.aria-label="link destino {{link.name}}" [href]="link.url" class="text-gray-3">{{ link.name }}</a>
              </li>
            }
          </ul>
        </div>
      }
    </div>
  }@else {
    <mat-accordion>
      @for (seen of (mostSeen() | groupBy:'type'); track seen) {
        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
          >
          <mat-expansion-panel-header>
            <p class="link-title">
              <span>{{seen.key}}</span>
            </p>
          </mat-expansion-panel-header>
          <ul class="link-container">
            @for (link of seen.value; track link) {
              <li>
                <a attr.aria-label="link destino {{link.name}}" [href]="link.url" class="text-gray-3">{{ link.name }}</a>
              </li>
            }
          </ul>
        </mat-expansion-panel>
      }
    </mat-accordion>
  }
</div>

