import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DEFAULT_LANDING_CONFIGURATION } from '../../core/constant/default-landing-configuration.constant';
import { LandingMeta } from '../../core/model/landing-meta.model';
import { Router } from '@angular/router';
import { COCHA_URL } from 'src/environments/environment';
import { currentPathname } from 'src/app/core/utils/current-pathname.util';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private excludedPaths: string[] = ['/'];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object,
    private meta: Meta,
    private title: Title,
    private router: Router,
  ) { }

  addMetaTags(meta: Partial<LandingMeta>): void {
    this.removeMetaTags();
    this.title.setTitle(meta.title || DEFAULT_LANDING_CONFIGURATION.meta.title);
    this.meta.updateTag({ name: 'description', content: meta.description || DEFAULT_LANDING_CONFIGURATION.meta.description });
    this.meta.updateTag({ property: 'og:title', content: meta.title || DEFAULT_LANDING_CONFIGURATION.meta.title });
    this.meta.updateTag({ property: 'og:description', content: meta.description || DEFAULT_LANDING_CONFIGURATION.meta.description });
    this.meta.updateTag({ property: 'og:url', content: COCHA_URL });
    this.addRobots(Boolean(meta.noinfo));
    this.addOrganizationStructuredData();
  }

  removeMetaTags(): void {
    this.meta.removeTag("name='description'");
    this.meta.removeTag("name='og:title'");
    this.meta.removeTag("name='og:description'");
    this.meta.removeTag("name='og:url'");
    this.meta.removeTag("name='robots'");
  }

  addCanonicalLink(): void {
    if (!this.isExcludedPath()) {
      this.removeCanonicalLink();
      let link: HTMLLinkElement | null = this.document.querySelector('[rel="canonical"]');
      if (!link) {
        const canonical = `${COCHA_URL}/${currentPathname(this.router.parseUrl(this.router.url))}`;
        link = this.document.createElement('link');
        link.setAttribute('rel', 'canonical');
        link.setAttribute('href', canonical);
        this.document.head.appendChild(link);
      }
    }
  }

  addRobots(noinfo: boolean, forceLoadRobots?: boolean) {
    if (noinfo) {
      const content = this.isExcludedPath() ? 'index, follow' : 'noindex, nofollow';
      this.meta.updateTag({ name: 'robots', content: content });
    }
    if (forceLoadRobots) {
      this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    }
    // FUTURE: Esto deberia ser reemplazado por un desarrollo que incluya agregar el campo robots dentro de la metadata de las landings
    if (['/vuelos', '/hoteles', '/paquetes', '/cocha-hits', '/asistencia-al-viajero'].includes(this.router.url)) {
      this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    }
  }

  removeCanonicalLink(): void {
    Array.from(this.document.querySelectorAll(`link[rel='canonical']`)).forEach(el => el.remove());
  }

  isExcludedPath(): boolean {
    return this.excludedPaths.some(url => this.router.url === url);
  }

  addStructuredDataToHead(name: string, price: number) {
    const data = {
      "@context": "http://schema.org",
      "@type": "Product",
      "name": name,
      "offers": {
        "@type": "Offer",
        "price": price,
        "priceCurrency": "CLP",
      }
    };
    const script = this.document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(data);
    this.document.head.appendChild(script);
  }

  addOrganizationStructuredData() {
    if (this.router.url === '/') {
      const structuredData = [
        {
          "@context": "https://schema.org",
          "@type": "Corporation",
          "url": "https://www.cocha.com/",
          "name": "Cocha"
        },
        {
          "@context": "https://schema.org",
          "@type": "OnlineStore",
          "image": "https://gcp-production-cdn.cocha.cloud/images/pop/mails/logo-cocha.png",
          "url": "https://www.cocha.com/",
          "sameAs": ["https://www.cocha.com/nuestra-empresa"],
          "logo": "https://static-cdn-prod.cocha.cloud/images/logo/logo_cocha.png",
          "name": "Cocha",
          "description": "Como una agencia de viajes con más de 70 años de trayectoria y liderazgo en la industria, buscamos ayudar a personas y empresas a viajar mejor.",
          "telephone": "+56224641300"
        },
        {
          "@context": "https://schema.org",
          "@type": "TravelAgency",
          "image": "https://gcp-production-cdn.cocha.cloud/images/pop/mails/logo-cocha.png",
          "url": "https://www.cocha.com/",
          "logo": "https://static-cdn-prod.cocha.cloud/images/logo/logo_cocha.png",
          "name": "Cocha",
          "description": "Como una agencia de viajes con más de 70 años de trayectoria y liderazgo en la industria, buscamos ayudar a personas y empresas a viajar mejor.",
          "telephone": "+56224641300",
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Avenida El Bosque Norte 0430",
            "addressLocality": "Chile",
            "addressCountry": "CL",
            "addressRegion": "Region Metropolitana",
            "postalCode": "7550092"
          }
        }
      ];
      const script = this.document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(structuredData);
      this.document.head.appendChild(script);
    }
  }

  addFAQStructuredData(elements: any[]): void {
    const mainEntity = elements
      .filter(element => element.type === 'hm-question')
      .flatMap(element =>
        element.questions.map((qa: { question: any; answer: any; }) => ({
          "@type": "Question",
          "name": qa.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": qa.answer
          }
        }))
      );

    if (mainEntity.length > 0) {
      const faqStructuredData = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": mainEntity
      };

      const script = this.document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(faqStructuredData);
      this.document.head.appendChild(script);
    }
  }

  addSEOStructuredData(data: any): void {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": data.meta?.faqMeta?.faqs.map((faq: { question: any; answer: any; }) => ({
        "@type": "Question",
        "name": faq.question,
        "acceptedAnswer": {
          "@type": "Answer",
          "text": faq.answer
        }
      })) || []
    };

    const script = this.document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(structuredData);
    this.document.head.appendChild(script);
  }

  buildMetaLanding(meta: LandingMeta) {
    this.addMetaTags(meta);
    this.addCanonicalLink();
    this.addRobots(Boolean(meta.noinfo), true);
  }
}
