import { Component, input, Input } from '@angular/core';

@Component({
  selector: 'landing-seals',
  templateUrl: './landing-seals.component.html',
  styleUrls: ['./landing-seals.component.scss'],
  standalone: true,
  imports: []
})
export class LandingSealsComponent {
  seals = input<string[]>([]);
}
