import { Component, input } from '@angular/core';
import { GroupByPipe } from '../../../../shared/pipes/group-by.pipe';
import { MatButtonModule } from '@angular/material/button';
import { NgClass } from '@angular/common';

export interface PaymentMethod {
  section: string;
  title: string;
  subtitle: string;
  images: string[];
}



@Component({
  selector: 'landing-payment-method',
  templateUrl: './landing-payment-method.component.html',
  styleUrls: ['./landing-payment-method.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    MatButtonModule,
    GroupByPipe
  ]
})
export class LandingPaymentMethodComponent {
  title = input<string>('');
  showBottomTextAndButton = input(false);
  paymentMethods = input<PaymentMethod[]>([]);
}
