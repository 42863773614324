import { Component, computed, EventEmitter, input, Input, OnInit, output, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe } from '@angular/common';
import { SUBSCRIBE_URL } from '../../../../../environments/environment';
import { ApisService } from '../../../../shared/services/apis.service';
import { GoogleAnalyticsService } from '../../../../shared/services/google-analytics.service';
import { IsMobileService } from '@cocha/ngx-codex';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'landing-subscribe',
  templateUrl: './landing-subscribe.component.html',
  styleUrls: ['./landing-subscribe.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class LandingSubscribeComponent implements OnInit {
  title = input.required<string>();
  titleMobile = input.required<string>();
  subtitle = input.required<string>();
  source = input.required<string>();
  endpoint = input<string>(SUBSCRIBE_URL);

  isMobile = toSignal(this.isMobileService.checkMobile$());
  currentTitle = computed(() => this.isMobile() ? (this.titleMobile() ?? this.title()) : this.title());
  emailControl = this.formBuilder.control<string | null>(null, [Validators.email, Validators.required]);
  newsletterResponse: any;
  showErrorNewsletterRequest = false;


  constructor(
    private formBuilder: FormBuilder,
    private apisService: ApisService,
    private isMobileService: IsMobileService,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) { }

  ngOnInit(): void {
    this.emailControl.valueChanges.subscribe((value: string | null) => {
      this.emailControl.setValue((value || '').trim(), { emitEvent: false });
    });
  }

  subscribeEmail(emailControl: FormControl) {
    if (emailControl.valid) {
      const data = {
        email: emailControl.value,
        optIn: 1,
        tags: ['suscriptor_' + (this.source || 'web')]
      };
      this.apisService.subscribeToNewsletter(this.endpoint(), data)
        .subscribe({
          next: (response: { msg?: string }) => {
            this.showErrorNewsletterRequest = false;
            this.newsletterResponse = response;
            this.googleAnalyticsService.sendGoogleAnalitycsEvent({ label: [response.msg ? 'OK' : 'NOK'], category: 'Suscripción', action: 'clic' });
          },
          error: (error) => {
            this.newsletterResponse = error;
            this.showErrorNewsletterRequest = true;
          },
        });
    }
  }

}
