import { Component, input } from '@angular/core';
import { IsMobileService } from '@cocha/ngx-codex';
import { GroupByPipe } from '../../../../shared/pipes/group-by.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { toSignal } from '@angular/core/rxjs-interop';

export interface MostSeenLinks {
  type: string;
  name: string;
  url: string;
}

@Component({
  selector: 'landing-most-seen-links',
  templateUrl: './landing-most-seen-links.component.html',
  styleUrls: ['./landing-most-seen-links.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    GroupByPipe,
  ]
})
export class LandingMostSeenLinksComponent {
  title = input<string>();
  mostSeen = input<MostSeenLinks[]>([]);

  isMobile = toSignal(this.isMobileService.checkMobile$(), { initialValue: true });
  panelOpenState: boolean = false;

  constructor(private isMobileService: IsMobileService) { }
}
