import { Component, computed, effect, ElementRef, input, Renderer2, viewChild } from '@angular/core';
import { IsMobileService, CarouselComponent, CarouselItemDirective } from '@cocha/ngx-codex';
import { NgClass, NgStyle } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
@Component({
  selector: 'landing-tips',
  templateUrl: './landing-tips.component.html',
  styleUrls: ['./landing-tips.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgStyle,
    CarouselComponent,
    CarouselItemDirective,
  ]
})
export class LandingTipsComponent {
  columns = input.required<number | string>();
  title = input.required<string>();
  bgcolor = input<string>();
  data = input<any[]>([]);

  dataByColumns = computed(() => {
    const chunkSize = Number(this.columns());
    const dataCopy = [...this.data()];
    const columnsData: any[][] = [];

    while (dataCopy.length) {
      const chunk = dataCopy.splice(0, chunkSize);
      columnsData.push(chunk);
    }
    return columnsData
  });

  isMobile = toSignal(this.isMobileService.checkMobile$(), { initialValue: true });
  containerElem = viewChild.required<ElementRef<HTMLElement>>('containerElem');

  constructor(
    private isMobileService: IsMobileService,
    private renderer: Renderer2
  ) {
    effect(() => {
      const bgColor = this.bgcolor();
      if (bgColor) {
        this.renderer.setProperty(this.containerElem().nativeElement, '--bg-tips-color', bgColor);
      }
    })
  }

  goTo(url: string) {
    url && window.open(url, '_blank');
  }
}
