<div class="landing-suscribe">
  <h4 class="title fw-400" [innerHTML]="currentTitle()"></h4>
  @if (subtitle(); as subtitle) {
  <h5 class="fw-500 mt-1" [innerHTML]="subtitle"></h5>
  }

  <div class="subscribe-form">
    <div class="input-btn">
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <input matInput placeholder="Ingresa tu email" autocomplete="off" [formControl]="emailControl">
      </mat-form-field>
      <button type="button" class="btn-large" mat-raised-button color="primary" (click)="subscribeEmail(emailControl)">
        Quiero recibirlas
      </button>
    </div>
    <div class="messages">
      @if (emailControl.invalid && emailControl.touched && !emailControl.pristine && emailControl.errors?.['email']) {
      <mat-hint>
        <p>*Por favor, ingresa un email válido.</p>
      </mat-hint>
      }
      @if (emailControl.valid && newsletterResponse?.msg !== 'OK' && showErrorNewsletterRequest) {
      <mat-hint class="d-flex align-items-center error">
        <p>Ha ocurrido un error, por favor intenta nuevamente</p>
        <button mat-stroked-button class="h-25 ml-3 bg-alliance" (click)="newsletterResponse = null">Reintentar</button>
      </mat-hint>
      }
      @if (newsletterResponse?.msg === 'OK') {
      <mat-hint class="success">
        <p><span class="fw-500">¡Gracias!</span> Te hemos enviado un email a: {{emailControl.value}}</p>
        <p>Prepárate para recibir nuestras mejores ofertas.</p>
      </mat-hint>
      }
    </div>
  </div>

  <span class="more-info">
    Para más información revisa las
    <a aria-label="link politicas de privacidad" href="https://www.cocha.com/politicas-de-privacidad" target="_blank">
      Políticas de Privacidad.
    </a>
  </span>
</div>