@if (paymentMethods();as paymentMethods) {
  <div class="py-5 d-flex flex-column">
    <h4 class="landing-payment-method-title">
      @if (title(); as title) {
        <span class="fw-400">{{ title }}</span>
      } @else {
        Es más fácil pagar en <span class="fw-500">COCHA.com</span>
      }
    </h4>
    <div class="wrapper-box-payment-method flex-center-center">
      @for (payment of (paymentMethods | groupBy:'section') ; track payment; let i = $index) {
        <div class="box-payment-method"
          [ngClass]="{'hide': i>0 }">
          <h5 class="box-payment-method-title"> {{ payment.key }}</h5>
          <div class="box-payment-method-types">
            @for (item of payment.value; track item) {
              <div class="box-payment-method-type flex-center-center flex-column"
                [ngClass]="{'mx-12': item.imgmargin}">
                <div class="d-flex flex-row flex-nowrap justify-content-around">
                  @for (image of item.images; track image) {
                    <img [src]="image" alt="imagen tarjeta de credito o método de pago"
                      [ngClass]="{'w-100': item.images.length === 1 && payment.key === 'Tarjetas de Crédito'}">
                  }
                </div>
                <span class="box-payment-method-type-subtitle fw-500 mt-2"> {{ item.title }} </span>
                @if (item.subtitle) {
                  <span class="box-payment-type-subtitle2 fw-300"> {{ item.subtitle }} </span>
                }
                @if (item.discount) {
                  <div class="box-payment-method-type-discount">
                    <span class="fw-500 pr-1">{{item.discount}}%</span>
                    <span>dcto.</span>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      }
    </div>
    @if (showBottomTextAndButton()) {
      <div class="see-detail flex-center-center">
        <p> Descubre más promociones y beneficios </p>
        <a  aria-label="link ver detalle pago en cuotas" href="https://www.cocha.com/pago-en-cuotas" mat-stroked-button color="primary">Ver detalle</a>
      </div>
    }
  </div>
}